<template>
  <div ref="ctxRef" class="w-full transform-gpu">
    <div
      class="marquee relative -left-2/4 flex min-w-full transform-gpu flex-nowrap gap-6 whitespace-nowrap will-change-transform"
    >
      <div
        v-for="idx in repeat"
        :key="idx"
        :aria-hidden="idx !== 1 && true"
        class="text-stroke flex flex-shrink-0 transform-gpu items-center overflow-hidden whitespace-nowrap py-1 font-fat text-fatBigMobile opacity-50 lg:text-fatBig"
        :class="styleVariant === 'cyan' ? 'one-stop' : 'text-white'"
      >
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'

interface MarqueeScrubProps {
  direction?: 'left' | 'right'
  repeat?: number
  text: string
  styleVariant?: 'white' | 'cyan'
}

withDefaults(defineProps<MarqueeScrubProps>(), {
  direction: 'left',
  repeat: 2
})

const isMounted = ref(false)
const ctxRef = ref<HTMLElement | null>(null)
let ctx: any = null
const { isMobile } = useDevice()

onMounted(() => {
  isMounted.value = true
  if (ctxRef.value) {
    ctx = gsap.context((self: any) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ctxRef.value,
          start: 'top bottom',
          end: 'bottom -25%',
          invalidateOnRefresh: true,
          scrub: true
        }
      })

      tl.to([self.selector('.marquee')], {
        xPercent: isMobile ? -200 : 50,
        ease: 'none'
      })
    }, ctxRef.value)
  }
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
})
</script>

<style lang="postcss" scoped>
.text-stroke {
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: theme('colors.white');
}

.one-stop {
  background: linear-gradient(264.54deg, #12e2ff 31.12%, rgba(18, 226, 255, 0) 92.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 14px 17px rgba(0, 209, 255, 0.22);
  -webkit-text-stroke-color: theme('colors.cyan.DEFAULT') !important;
}
</style>
