<template>
  <div ref="ctxRef">
    <canvas
      ref="canvasRef"
      class="max-w-full"
      :width="width"
      :height="height"
      @mouseenter="!viewportPlay ? animPlay() : undefined"
      @mouseleave="!viewportPlay ? animReverse() : undefined"
    />
  </div>
</template>

<script setup lang="ts">
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

interface ImageSequenceProps {
  loading?: boolean
  hero: string
  width?: number
  height?: number
  startFrame?: number
  countFrames?: number
  perSecond?: number
  viewportPlay?: boolean
}

const props = withDefaults(defineProps<ImageSequenceProps>(), {
  width: 500,
  height: 616,
  startFrame: 1,
  countFrames: 13,
  perSecond: 24,
  viewportPlay: false
})

const ctxRef = ref<HTMLElement | null>(null)
let ctx: gsap.Context | null = null

const canvasRef = ref<HTMLCanvasElement | null>(null)
const canvasTween = ref<GSAPTween | null>(null)
const canvas = reactive({ frame: props.startFrame || 1 })
const frames = ref<HTMLImageElement[]>([])

const renderFrame = () => {
  if (!canvasRef.value) {
    return
  }
  const ctx = canvasRef.value?.getContext('2d')
  ctx?.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height)
  ctx?.drawImage(frames.value[canvas.frame - props.startFrame], 0, 0)
}

const animPlay = () => {
  if (canvasTween.value) {
    canvasTween.value.play()
  }
}

const animReverse = () => {
  if (canvasTween.value) {
    canvasTween.value.reverse()
  }
}

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger)

  if (ctxRef.value) {
    for (let i = props.startFrame; i <= props.countFrames; i++) {
      const img = new Image()
      /*
      const src1x = new URL(
        `/assets/images/bonus/sequence/${props.hero}/${props.hero}_${i}.webp`,
        import.meta.url
      ).href
      */
      const src2x = new URL(
        `/assets/images/bonus/sequence/${props.hero}/${props.hero}_${i}@2x.webp`,
        import.meta.url
      ).href
      img.src = src2x
      // img.srcset = `${src1x} 1x, ${src2x} 2x`
      frames.value.push(img)
    }
    frames.value[0].onload = () => {
      renderFrame()
    }

    ctx = gsap.context(() => {
      canvasTween.value = gsap.to(canvas, {
        frame: props.countFrames,
        snap: 'frame',
        duration: Math.abs(props.countFrames - canvas.frame) / props.perSecond,
        paused: true,
        ease: Linear.easeNone,
        onUpdate: renderFrame
      })

      if (props.viewportPlay) {
        ScrollTrigger.create({
          trigger: ctxRef.value,
          start: 'center 50%',
          end: 'bottom 50%',
          onEnter: () => animPlay(),
          onEnterBack: () => animReverse()
        })
      }
    }, ctxRef.value)
  }
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
})
</script>
