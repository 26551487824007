<template>
  <div ref="ctxRef" class="my-20 flex w-full justify-center">
    <div>
      <svg
        class="line-l"
        width="498"
        height="88"
        viewBox="0 0 498 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M497.123 1.50004L168.877 43.7503L497.123 86.0005"
          stroke="url(#paint0_linear_1078_17010)"
          stroke-width="3"
          stroke-miterlimit="16"
        />
        <path d="M175.243 43.7579H0.242676" stroke="#12E2FF" stroke-width="4" />
        <defs>
          <linearGradient
            id="paint0_linear_1078_17010"
            x1="168.877"
            y1="43.7503"
            x2="497.123"
            y2="43.7503"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#12E2FF" />
            <stop offset="1" stop-color="#12E2FF" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="grow-0 self-center">
      <Button
        :to="'/contact'"
        label="Get a tailored offer"
        variant="outline"
        size="lg"
        class="cta"
      />
    </div>
    <div>
      <svg
        class="line-r"
        width="498"
        height="88"
        viewBox="0 0 498 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.87743 1.50004L329.123 43.7503L0.877432 86.0005"
          stroke="url(#paint0_linear_1078_17012)"
          stroke-width="3"
          stroke-miterlimit="16"
        />
        <path d="M322.757 43.7579H497.757" stroke="#12E2FF" stroke-width="4" />
        <defs>
          <linearGradient
            id="paint0_linear_1078_17012"
            x1="329.123"
            y1="43.7503"
            x2="0.877441"
            y2="43.7503"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#12E2FF" />
            <stop offset="1" stop-color="#12E2FF" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'

const isMounted = ref(false)
const ctxRef = ref<HTMLElement | null>(null)
let ctx: any = null

onMounted(() => {
  isMounted.value = true

  if (ctxRef.value) {
    ctx = gsap.context((self: any) => {
      gsap.set([self.selector('.line-l')], { autoAlpha: 0, x: 100 })
      gsap.set([self.selector('.line-r')], { autoAlpha: 0, x: -100 })
      gsap.set(self.selector('.cta'), {
        scale: 0
      })

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ctxRef.value,
          start: 'top 70%',
          end: '+=500'
        }
      })

      tl.to([self.selector('.line-l'), self.selector('.line-r')], {
        duration: 1,
        autoAlpha: 1,
        x: 0,
        ease: 'power1.out'
      })
      tl.to(
        self.selector('.cta'),
        {
          duration: 1,
          scale: 1,
          ease: 'back.out(1.7)'
        },
        '-=1'
      )
    }, ctxRef.value)
  }
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
})
</script>
