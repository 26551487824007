<template>
  <div
    class="min-h-[23px] text-white lg:min-h-[86px]"
    :class="[isMounted ? 'opacity-100' : 'opacity-0']"
  >
    <template v-for="(item, idx) in data" :key="idx">
      <TextAnimation v-if="isActiveIdx === idx">{{ item }}</TextAnimation></template
    >
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Array as PropType<string[]>,
    default: () => []
  }
})

const isMounted = ref(false)
const isActiveIdx = ref(-1)
const interval = ref(4000)

const { resume } = useIntervalFn(
  () => {
    if (!isMounted.value) {
      return
    }
    isActiveIdx.value = (isActiveIdx.value + 1) % props.data.length
  },
  interval,
  { immediate: false }
)

onMounted(() => {
  isMounted.value = true
  resume()
})
</script>
