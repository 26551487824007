<template>
  <div
    ref="ctxRef"
    class="mx-auto flex w-fit flex-col justify-between gap-12 lg:max-w-6xl lg:flex-row"
  >
    <div v-for="(feature, idx) in data" :key="idx" class="about-block max-w-[296px]">
      <div class="relative">
        <div class="fx-image-box relative mb-5 aspect-3/2 w-full">
          <div class="fx-image absolute top-1/2 w-full -translate-y-1/2 overflow-hidden">
            <Image
              class="absolute top-1/2 w-full -translate-y-1/2"
              :src="
                feature.media?.responsiveImage
                  ? feature.media?.responsiveImage?.src
                  : feature.media?.url
              "
              :srcset="
                feature.media?.responsiveImage
                  ? feature.media?.responsiveImage?.webpSrcSet
                  : undefined
              "
              :width="
                feature.media?.responsiveImage
                  ? feature.media?.responsiveImage?.width
                  : feature.media?.width
              "
              :height="
                feature.media?.responsiveImage
                  ? feature.media?.responsiveImage?.height
                  : feature.media?.height
              "
              sizes="(min-width: 1040px) 296px, (min-width: 660px) 576px, 90.59vw"
              :alt="feature.media?.alt! || ''"
            />
          </div>
        </div>
        <svg
          class="fx-top absolute -top-5 left-1/2 -translate-x-1/2"
          width="358"
          height="83"
          viewBox="0 0 358 83"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_2017_3866)">
            <path
              d="M26.4282 56.7426L68.7749 26.3057H288.973L331.32 56.7426L285.296 30.7529H72.4525L26.4282 56.7426Z"
              fill="#12E2FF"
            />
          </g>
          <path
            d="M20.748 57.806L55.6757 24.2628C61.0669 19.0852 68.2519 16.1938 75.7267 16.1938H282.021C289.496 16.1938 296.681 19.0852 302.072 24.2628L337 57.806L295.712 26.6537C291.945 23.8115 287.355 22.274 282.636 22.274H75.1123C70.3933 22.274 65.8029 23.8115 62.0359 26.6537L20.748 57.806Z"
            fill="#12E2FF"
          />
          <defs>
            <filter
              id="filter0_f_2017_3866"
              x="0.428223"
              y="0.305664"
              width="356.892"
              height="82.437"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="13" result="effect1_foregroundBlur_2017_3866" />
            </filter>
          </defs>
        </svg>
        <svg
          class="fx-bottom absolute -bottom-5 left-1/2 -translate-x-1/2 rotate-180"
          width="358"
          height="83"
          viewBox="0 0 358 83"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_2017_3866)">
            <path
              d="M26.4282 56.7426L68.7749 26.3057H288.973L331.32 56.7426L285.296 30.7529H72.4525L26.4282 56.7426Z"
              fill="#12E2FF"
            />
          </g>
          <path
            d="M20.748 57.806L55.6757 24.2628C61.0669 19.0852 68.2519 16.1938 75.7267 16.1938H282.021C289.496 16.1938 296.681 19.0852 302.072 24.2628L337 57.806L295.712 26.6537C291.945 23.8115 287.355 22.274 282.636 22.274H75.1123C70.3933 22.274 65.8029 23.8115 62.0359 26.6537L20.748 57.806Z"
            fill="#12E2FF"
          />
          <defs>
            <filter
              id="filter0_f_2017_3866"
              x="0.428223"
              y="0.305664"
              width="356.892"
              height="82.437"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="13" result="effect1_foregroundBlur_2017_3866" />
            </filter>
          </defs>
        </svg>
      </div>
      <Heading
        :size="3"
        :isAnimated="false"
        class="fx-title mb-2 text-cyan drop-shadow-[0px_0px_64px_#12E2FF] lg:mb-5"
        >{{ feature.title }}</Heading
      >
      <div class="fx-description" v-html="feature.body"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap'
import { IndexRecord } from '~/types/datocms'

export interface AboutFeaturesProps {
  data: IndexRecord['aboutFeatures'][]
}

defineProps<AboutFeaturesProps>()

const isMounted = ref(false)
const ctxRef = ref<HTMLElement | null>(null)
let ctx: gsap.Context | null = null
let tl: GSAPTimeline | null = null

onMounted(() => {
  isMounted.value = true

  if (ctxRef.value) {
    ctx = gsap.context((self: any) => {
      self.selector('.about-block').forEach((el: HTMLElement) => {
        const elTitle = el.querySelector('.fx-title')
        const elTop = el.querySelector('.fx-top')
        const elBottom = el.querySelector('.fx-bottom')
        const elDescription = el.querySelector('.fx-description')
        const elImage = el.querySelector('.fx-image')
        const elImageBox = el.querySelector('.fx-image-box')
        const height = elImageBox?.clientHeight || 0

        gsap.set([elTop], { y: height / 2 })
        gsap.set([elBottom], { y: -(height / 2) })
        gsap.set([elImage], { height: 0 })
        gsap.set([elTitle, elDescription], { autoAlpha: 0, y: 10 })

        tl = gsap.timeline({
          scrollTrigger: {
            trigger: ctxRef.value,
            start: 'top 95%'
          }
        })
        tl.addLabel('start')
        tl.to(
          [elTop, elBottom],
          {
            duration: 0.8,
            y: 0,
            ease: 'power1.out'
          },
          'start'
        )
        tl.to(
          elImage,
          {
            duration: 0.8,
            height: 197,
            ease: 'power1.out'
          },
          'start'
        )
        tl.to(elTitle, {
          duration: 0.5,
          autoAlpha: 1,
          y: 0,
          ease: 'power1.out'
        })
        tl.to(
          elDescription,
          {
            duration: 0.6,
            autoAlpha: 1,
            y: 0,
            ease: 'power1.out'
          },
          '-=0.2'
        )
      })
    }, ctxRef.value)
  }
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
})
</script>
