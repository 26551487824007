<template>
  <div class="w-full border-b-4 border-primary last:border-none">
    <button
      class="flex w-full transform-gpu cursor-pointer items-center justify-between py-1.5 pb-0.5"
      :aria-controls="`acc-panel-${idx}`"
      :aria-expanded="active"
      @click="expand"
    >
      <div
        class="text-left font-tech text-body text-subTech1 uppercase text-cyan transition-colors duration-300 ease-in-out hover:text-white backdrop:lg:text-subTech"
      >
        <slot name="title" />
      </div>
    </button>
    <Transition
      name="accordion"
      @enter="setHeight"
      @afterEnter="unsetHeight"
      @beforeLeave="setHeight"
      @afterLeave="unsetHeight"
    >
      <div
        v-show="active"
        :id="`acc-panel-${idx}`"
        :aria-hidden="!active"
        class="transform-gpu text-sm text-cyan lg:text-body"
      >
        <slot name="content" />
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
export interface AccordionPanelProps {
  idx: number
  active: boolean
}

defineProps<AccordionPanelProps>()
const emit = defineEmits(['expand'])

const isAnimating = ref(false)

const expand = () => {
  if (isAnimating.value) {
    return
  }
  emit('expand')
}

function setHeight(el: HTMLElement) {
  isAnimating.value = true
  el.style.height = el.scrollHeight + 'px'
}

function unsetHeight(el: HTMLElement) {
  isAnimating.value = false
  el.style.height = ''
}
</script>

<style lang="postcss">
.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition:
    height 500ms ease-out 0.1s,
    opacity 600ms ease-out;

  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
