<template>
  <div>
    <ScrollSection id="hero" class="section relative transform-gpu">
      <div
        class="relative flex h-screen transform-gpu flex-col justify-center"
        @mouseleave="useEvent('cursor:scale', false)"
      >
        <div class="container text-cyan">
          <div class="grid-default">
            <div class="col-span-full lg:col-span-10">
              <Heading :size="100" class="h1-blur narrow:!text-[18vh] xs:!text-[65px]"
                ><div v-html="data?.index!.title"></div
              ></Heading>

              <div class="mt-5 max-w-lg !font-tech !text-subTech uppercase lg:mt-14">
                <Heading :size="5" class="text-[1.25rem] narrow:!text-[3.8vh]">
                  <div>{{ data?.index!.subheading }}</div>
                </Heading>
                <TextShuffle
                  class="text-[1.25rem] lg:text-sub narrow:!text-[3.8vh]"
                  :data="data?.index!.leadText?.split(',')"
                />
                <button
                  class="button-play relative my-7 inline-block h-[37px] w-[150px] rounded-full shadow-[0px_0px_44px_#12E2FF] lg:hidden"
                  @click="showVideo"
                >
                  <span
                    class="absolute left-11 top-[8px] block whitespace-nowrap !font-sans text-[16px] font-semibold text-cyan"
                  >
                    Play video</span
                  >
                </button>
              </div>
              <HeroFeatures class="pointer-events-auto" />
            </div>
          </div>
        </div>
      </div>
      <ScrollDown class="absolute bottom-4 right-4 hidden lg:block" />
    </ScrollSection>

    <ScrollSection id="about" class="section pointer-events-auto">
      <div class="relative py-[20vh] lg:pt-[50vh]">
        <!--
        <ClientOnly v-if="data?.index?.aboutSplineUrl && isDesktop">
          <SplineRuntime
            :url="data?.index?.aboutSplineUrl"
            :width="660"
            :height="880"
            class="absolute left-1/2 top-[10%] hidden h-auto w-full -translate-x-1/2 lg:block"
            responsive
          />
        </ClientOnly>
        -->
        <div class="container relative">
          <div class="mx-auto mb-20 max-w-2xl text-center">
            <Heading :level="2" :size="2" class="mb-2 !leading-[0.9]">{{
              data?.index!.aboutHeading
            }}</Heading>
            <p class="mx-auto max-w-lg text-body">
              {{ data?.index!.aboutSubheading }}
            </p>
          </div>

          <AboutFeatures :data="data?.index?.aboutFeatures" />
        </div>
      </div>
      <div class="relative -mb-24 lg:mb-[-200px]">
        <svg
          class="video-bonuses h-auto w-full"
          width="1440"
          height="727"
          viewBox="0 0 1440 727"
          fill="none"
        ></svg>
        <div class="absolute left-1/2 top-1/2 h-[61%] w-[85%] -translate-x-1/2 translate-y-[-37%]">
          <div class="video-shadow h-full w-full overflow-hidden rounded-full">
            <VideoHTML
              :width="1920"
              :height="1920"
              :sources="[
                {
                  src: videoBonuses,
                  type: 'video/mp4'
                }
              ]"
              preload="metadata"
              class="h-full w-full object-cover"
              muted
              autoplay
              loop
              playsinline
            />
          </div>
        </div>
        <div class="absolute -bottom-28 left-0 h-36 w-full bg-bonusGradient"></div>
      </div>
      <div class="slider w-full snap-x snap-mandatory overflow-auto pb-10 lg:overflow-visible">
        <div class="mx-auto flex w-fit items-end justify-center lg:max-w-full">
          <BonusListItem
            v-for="(bonus, idx) in data?.index?.bonuses"
            :key="idx"
            :data="bonus"
            class="w-[28vw] sm:w-[30vw] lg:max-w-[14vw]"
            :glowClasses="{
              'bg-[#16C34E] h-4': idx === 1,
              'bg-[#1587C0] h-[22px]': idx === 2,
              'bg-[#BB9953] h-5': idx === 3,
              'bg-[#ED8538] h-5': idx === 4,
              'bg-[#4F7663] h-4': idx === 0 || idx === 5
            }"
          />
        </div>
      </div>
      <!--
      <div class="text-center text-xs text-cyan">
        Click on character to learn more <Icon name="chevron-up" color="#12E2FF" />
      </div>
      -->
      <div class="mb-[10vh] mt-[5vh] w-full overflow-hidden lg:my-[10vh]">
        <MarqueeScrub text="IMMERSIVE GAMIFICATION" :repeat="3" />
      </div>
    </ScrollSection>

    <ScrollSection
      id="showroom"
      class="section showroom-background pointer-events-auto overflow-hidden pt-[10vh] lg:mt-[0vh] lg:pt-0"
    >
      <!-- Showroom -->
      <section class="mb-[5vh] flex flex-col justify-center px-4 lg:mb-0 lg:px-0">
        <Showroom
          :heading="data?.index!.showroomHeading"
          :subheading="data?.index!.showroomSubheading"
          :features="data?.index!.showroomFeatures"
        />
      </section>
      <!-- / Showroom -->
      <div class="mb-[8vh] w-full overflow-hidden lg:mb-0">
        <MarqueeScrub text="SELF-DRIVEN ROLLER COASTER" :repeat="3" />
      </div>
    </ScrollSection>
    <ScrollSection id="tracks" class="section pointer-events-auto">
      <div
        id="section-tracks"
        class="flex min-h-screen flex-col justify-center lg:mb-[25vh] lg:mt-[50vh]"
      >
        <Tracks
          class="my-20"
          :data="data?.index!.tracksFeatures"
          :heading="data?.index!.tracksHeading"
          :subheading="data?.index!.tracksSubheading"
        />
      </div>
    </ScrollSection>
    <ScrollSection id="all-in-one" class="section pointer-events-auto relative">
      <AllInOneSeparator />
      <section class="bg-all-in-one lg:-my-[150px]">
        <div class="dots overflow-hidden pb-24 pt-15 lg:pb-40 lg:pt-52">
          <div class="container text-cyan">
            <Heading
              :size="11"
              class="text mx-auto mb-3 max-w-[220px] text-center drop-shadow-[0px_0px_19px_rgba(255,255,255,0.5)] lg:mb-7 lg:max-w-full"
              >{{ data?.index!.allInOneHeading }}</Heading
            >
            <p
              class="mx-auto max-w-xl text-center text-sm drop-shadow-[0px_0px_19px_rgba(255,255,255,0.35)] lg:max-w-[800px] lg:text-body"
            >
              {{ data?.index!.allInOneSubheading }}
            </p>

            <MarqueeScrub
              class="mb-8 mt-4 opacity-50 lg:mb-0"
              text="ONE STOP SHOP."
              :repeat="3"
              styleVariant="cyan"
            />
            <LazyAllInOneFeatures
              v-if="data?.index!.allInOneSplineUrl || data?.index!.allInOneFeatures.length"
              :data="data?.index!.allInOneFeatures"
              :spline="data?.index!.allInOneSplineUrl"
            />
            <LazyTestimonials
              v-if="data?.index!.allInOneTestimonials.length"
              :data="data?.index!.allInOneTestimonials"
              class="my-20"
            />
          </div>
        </div>
      </section>
      <AllInOneSeparator class="rotate-180" />
    </ScrollSection>
    <ScrollSection id="packages" class="section pointer-events-auto">
      <div class="pb-[10vh] lg:py-[20vh] lg:pt-[35vh]">
        <div id="packages-scene" class="flex flex-col justify-center overflow-hidden">
          <div class="container pt-16">
            <Heading :size="22" :level="2" class="text-center text-cyan lg:text-left">{{
              data?.index!.packagesHeading
            }}</Heading>

            <Planets :packages="data?.index!.packagesOptions" />
          </div>
        </div>

        <div class="text-center">
          <CtaDecor />
        </div>
      </div>
    </ScrollSection>
    <div
      v-if="Array.isArray(data?.index!.main) && data?.index!.main.length"
      id="components"
      class="section pointer-events-auto"
    >
      <component
        :is="resolveSectionComponent(section.__typename as SectionComponents)"
        v-for="section in data.index.main"
        :key="section.__typename"
        :data="section"
      />
    </div>
    <!--
    <FooterVisuals />
    -->
    <div id="contact" class="section container pointer-events-auto relative py-40">
      <div class="grid-default relative">
        <div class="col-span-full lg:col-span-4 lg:col-start-2">
          <Heading :size="1" class="mb-6 text-cyan">{{ data?.index!.contactHeading }}</Heading>
          <p class="mb-8 text-body">
            {{ data?.index!.contactSubheading }}
          </p>
          <Button label="Contact us" to="/contact" variant="outline" />
        </div>
      </div>
    </div>
    <ScrollSectionNav v-if="links" :links="links" class="pointer-events-auto hidden lg:block" />
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { toHead } from 'vue-datocms'
import { SectionComponents } from '~/types/components'
import { ModelApiKey } from '~/types/models'
import { useAppStore } from '~/stores/app'
import videoBonuses from '~/assets/videos/bonuses.mp4'

const { resolveSectionComponent } = useResolveComponent()

const route = useRoute()
// const { isDesktop } = useDevice()

const { data } = await useAsyncGql('Index', {
  options: {
    initialCache: false
  }
})

if (data?.value!.index) {
  route.meta.nuxtI18n = data
    ?.value!.index._allPublishTranslationLocales?.filter((x: any) => x.value)
    .map((x: any) => x.locale)
}

const datoHead = toHead((data.value as any).index.seo)
const i18nHeadLinks = useLocaleHeadLinks(data?.value!.index?._modelApiKey as ModelApiKey)

const links = [
  { title: 'Hero', href: '#hero' },
  { title: 'About', href: '#about' },
  { title: 'Showroom', href: '#showroom' },
  { title: 'Tracks', href: '#tracks' },
  { title: 'All in one', href: '#all-in-one' },
  { title: 'Packages', href: '#packages' }
]

useHead(() => {
  const metaTags = [...(datoHead.meta || [])]

  return {
    title: 'The ultimate fun.',
    link: [...i18nHeadLinks],
    meta: metaTags
  }
})

const { showVideo } = useModal()

definePageMeta({
  pageTransition: {
    name: 'index',
    mode: 'out-in',
    css: false,
    onEnter: (el, done) => {
      gsap.set(el, { autoAlpha: 0 })
      gsap
        .timeline({
          paused: true,
          onComplete() {
            done()
          }
        })
        .to(el, { autoAlpha: 1, duration: 0.4, ease: 'power1.out' })
        .play()
    },
    onLeave: (el, done) => {
      gsap
        .timeline({
          paused: true,
          onComplete: () => {
            done()
          }
        })
        .to(el, { autoAlpha: 0, duration: 0.4, ease: 'power1.out' })
        .play()
    }
  }
})

const store = useAppStore()

onMounted(() => {
  store.setHeaderVisibility(true)
  store.setEyeVisibility(true)
  store.heroSection = document.getElementById('hero')
  store.packagesSection = document.getElementById('packages')
  store.contactSection = document.getElementById('contact')
})

onBeforeUnmount(() => {
  store.setEyeVisibility(false)
})
</script>

<style lang="postcss">
.bg-all-in-one {
  background: linear-gradient(
      59.59deg,
      rgba(18, 226, 255, 0.18) 0.63%,
      rgba(18, 226, 255, 0) 42.69%
    ),
    linear-gradient(208.91deg, rgba(18, 226, 255, 0.3) -1.61%, rgba(18, 226, 255, 0) 23.79%),
    #113660;
}

.video-shadow {
  box-shadow: inset 0px 0px 24px 17px #3c3890;
  filter: drop-shadow(0px 0px 44px #7c3eff);
}

.showroom-background {
  background: url('~/assets/images/backgrounds/intersection.webp') no-repeat center top / 100% auto;
}

.video-bonuses {
  background: url('~/assets/images/backgrounds/video-bonuses.webp') no-repeat center / cover;
}

.dots {
  background-image: radial-gradient(rgba(18, 226, 255, 0.4) 0.8px, rgba(255, 255, 255, 0) 0);
  background-size: 40px 40px;
}

.h1-blur {
  text-shadow: 0px 0 20px rgba(18, 226, 255, 0.16);
}

.button-play {
  background: url('~/assets/images/ui/play-video.png') 0 0 no-repeat;
}
</style>
