<template>
  <div ref="ctxRef">
    <div class="container relative">
      <div class="box lg:absolute lg:-top-48 lg:right-10">
        <Box class="max-w-[600px]" :heading="heading" :text="subheading" />
      </div>
    </div>
    <div class="mx-auto w-full max-w-[1280px] transform-gpu">
      <div
        class="fx-tracks relative left-[-10%] mx-auto mb-24 aspect-video w-[calc(130%)] transform-gpu lg:left-0 lg:mb-12 lg:w-full xl:mb-6"
      >
        <Image
          :src="wireUnder"
          class="absolute inset-0"
          :width="1260"
          :height="724"
          alt="Spacekart track"
        />
        <Image :src="layer5" class="i i5" :width="1260" :height="724" alt="Spacekart track" />
        <Image :src="layer4" class="i i4" :width="1260" :height="724" alt="Spacekart track" />
        <Image :src="layer3" class="i i3" :width="1260" :height="724" alt="Spacekart track" />
        <Image :src="layer2" class="i i2" :width="1260" :height="724" alt="Spacekart track" />
        <Image :src="layer1" class="i i1" :width="1260" :height="724" alt="Spacekart track" />
        <Transition name="fade">
          <Image
            v-show="isScrubComplete && activeIdx === 0"
            :src="layerSafety"
            class="i"
            :width="1260"
            :height="724"
            alt="Spacekart Track Safety features"
          />
        </Transition>
        <Transition name="fade">
          <Image
            v-show="isScrubComplete && activeIdx === 1"
            :src="layerExperience"
            class="i"
            :width="1260"
            :height="724"
            alt="Spacekart Track Experience"
          />
        </Transition>
        <Transition name="fade">
          <Image
            v-show="isScrubComplete && activeIdx === 2"
            :src="layerSpace"
            class="i"
            :width="1260"
            :height="724"
            alt="Spacekart Track Space"
          />
        </Transition>
        <Image
          :src="wireOver"
          class="absolute inset-0"
          :width="1260"
          :height="724"
          alt="Spacekart track"
        />
      </div>
      <div class="mt-10 lg:container lg:mt-0">
        <div class="max-w-xl px-4 lg:px-0" aria-multiselectable="false">
          <ul role="tablist" class="mb-2 flex justify-between gap-8 sm:justify-start lg:mb-4">
            <li v-for="(item, idx) in data" :key="idx" role="presentation" class="relative">
              <span
                class="absolute left-1/2 mb-10 block w-px -translate-y-4 overflow-hidden"
                :class="{
                  '-top-24 h-24 lg:-top-28 lg:h-28': idx === 0,
                  '-top-18 h-18 lg:-top-20 lg:h-20': idx === 1,
                  '-top-14 h-14 lg:-top-12 lg:h-12': idx === 2
                }"
              >
                <span
                  class="absolute left-0 top-0 block h-full w-px bg-white transition-transform duration-500"
                  :class="isScrubComplete ? 'translate-y-0' : 'translate-y-full'"
                >
                  <Transition name="tabs">
                    <span
                      v-show="activeIdx === idx"
                      class="absolute left-0 top-0 block h-full w-full bg-cyan"
                    ></span>
                  </Transition>
                </span>
              </span>
              <button
                :id="`tab-label-${idx + 1}`"
                type="button"
                role="tab"
                class="font-tech text-subTech uppercase drop-shadow-[0px_0px_64px_#FFF] transition-colors duration-300 ease-in-out hover:text-cyan sm:text-subTechBig lg:text-head"
                :class="{
                  'text-cyan !drop-shadow-[0px_0px_64px_#12E2FF]':
                    activeIdx === idx && isScrubComplete
                }"
                :aria-controls="`tab-panel-${idx + 1}`"
                :aria-selected="activeIdx === idx"
                :aria-setsize="data.length"
                :aria-posinset="idx + 1"
                :tabindex="activeIdx === idx ? 0 : -1"
                @click="setActiveIdx(idx)"
              >
                {{ item.title }}
              </button>
            </li>
          </ul>
          <div class="min-h-[240px]">
            <div
              v-for="(item, idx) in data"
              v-show="activeIdx === idx"
              :id="`tab-panel-${idx + 1}`"
              :key="idx"
              role="tabpanel"
              :aria-labelledby="`tab-label-${idx + 1}`"
              :aria-hidden="activeIdx !== idx"
              tabindex="-1"
              class="text-white"
            >
              <div v-html="item.body"></div>
              <div class="mt-4 flex items-center gap-7">
                <div class="text-cyan">Want to know more about our tracks?</div>
                <div class="relative h-7 w-32">
                  <Button :to="'/contact'" variant="outline" label="Contact us" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import layer1 from '~/assets/images/tracks/layer-1.webp'
import layer2 from '~/assets/images/tracks/layer-2.webp'
import layer3 from '~/assets/images/tracks/layer-3.webp'
import layer4 from '~/assets/images/tracks/layer-4.webp'
import layer5 from '~/assets/images/tracks/layer-5.webp'
import layerSafety from '~/assets/images/tracks/safety.webp'
import layerExperience from '~/assets/images/tracks/experience.webp'
import layerSpace from '~/assets/images/tracks/space.webp'
import wireUnder from '~/assets/images/tracks/wire-under.webp'
import wireOver from '~/assets/images/tracks/wire-over.webp'

export interface TabProps {
  title: string
  body: string
}

defineProps({
  data: {
    type: Array as PropType<TabProps[]>,
    default: () => []
  },
  heading: {
    type: String,
    default: ''
  },
  subheading: {
    type: String,
    default: ''
  }
})

const activeIdx = ref<number | null>(0)

function setActiveIdx(idx: number) {
  if (!isScrubComplete.value) {
    return
  }
  activeIdx.value = idx
}

const isScrubComplete = ref(false)
const ctxRef = ref<HTMLElement | null>(null)
let ctx: any = null

onMounted(() => {
  if (ctxRef.value) {
    ctx = gsap.context((self: any) => {
      gsap.set(self.selector('.box'), {
        autoAlpha: 0,
        y: '30px'
      })

      gsap.set(self.selector('.i5'), {
        y: '10%'
      })
      gsap.set(self.selector('.i4'), {
        y: '-40%'
      })
      gsap.set(self.selector('.i3'), {
        y: '-60%'
      })
      gsap.set(self.selector('.i2'), {
        y: '-80%'
      })
      gsap.set(self.selector('.i1'), {
        y: '-130%'
      })

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: self.selector('.fx-tracks'),
          scrub: true,
          start: 'top 80%',
          end: 'bottom 80%',
          markers: false,
          onUpdate: (self) => {
            if (self.progress >= 0.82 && !isScrubComplete.value) {
              isScrubComplete.value = true
            } else if (self.progress < 0.82 && isScrubComplete.value) {
              isScrubComplete.value = false
            }
          }
        }
      })

      tl.to([self.selector(['.i1', '.i2', '.i3', '.i4', '.i5'])], {
        y: 0,
        ease: 'ease.inOut'
      })
      tl.to(
        self.selector('.box'),
        {
          autoAlpha: 1,
          y: 0,
          ease: 'ease.inOut'
        },
        '-=0.5'
      )
      useTimeoutFn(() => {
        ScrollTrigger.refresh()
      }, 1500)
    }, ctxRef.value)
  }
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
})
</script>

<style lang="postcss">
.i {
  @apply absolute left-1/2 top-[15%] max-w-[70%] -translate-x-1/2 transform-gpu will-change-auto;
}
</style>
