<template>
  <div ref="ctxRef" class="relative mx-auto w-fit">
    <Box
      class="fx-box-top max-w-[314px] text-cyan lg:absolute lg:left-20 lg:top-[20%]"
      :heading="heading"
      :text="subheading"
    />
    <div class="mb-10 aspect-square lg:mb-0 lg:max-w-screen-2xl lg:px-40 lg:pb-20 lg:pt-80">
      <div class="fx-video transform-gpu will-change-transform">
        <!-- <VideoHTML
          class="-ml-28 w-[calc(100%+14rem)] max-w-none lg:ml-0 lg:w-full lg:max-w-full"
          :width="1600"
          :height="1600"
          :sources="[
            {
              src: videoCarSafari,
              type: 'video/mp4; codecs=&quot;hvc1&quot;'
            },
            {
              src: videoCarChrome,
              type: 'video/webm'
            }
          ]"
          preload="metadata"
          playsinline
          muted
        /> -->
        <div class="-ml-28 w-[calc(100%+14rem)] max-w-none lg:ml-0 lg:w-full lg:max-w-full">
          <ImageSequence
            hero="gocart"
            :count-frames="38"
            :width="1000"
            :height="1000"
            viewport-play
          />
        </div>
      </div>
    </div>
    <AccordionFeatures
      class="fx-box-bot ml-auto mr-0 lg:absolute lg:bottom-5 lg:right-5 lg:mx-auto"
      :data="features"
    />
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { IndexRecord } from '~/types/datocms'
// import videoCarChrome from '~/assets/videos/24_006_SpaceKart_WebSeq_trans_001-vp9-chrome.webm'
// import videoCarSafari from '~/assets/videos/24_006_SpaceKart_WebSeq_trans_001-hevc-safari.mp4'

interface ShowroomProps {
  heading: string
  subheading: string
  features: IndexRecord['showroomFeatures'][]
}

defineProps<ShowroomProps>()

const isMounted = ref(false)
const isTopBoxVisible = ref(false)
const isAccordionVisible = ref(false)
const ctxRef = ref<HTMLElement | null>(null)
let ctx: any = null
let tl: GSAPTimeline | null = null

onMounted(() => {
  isMounted.value = true

  if (ctxRef.value) {
    const mm = gsap.matchMedia()
    mm.add('(min-width: 1024px)', () => {
      ctx = gsap.context((self: any) => {
        const elVideo = self.selector('.fx-video')
        const elBoxTop = self.selector('.fx-box-top')
        const elBoxBot = self.selector('.fx-box-bot')
        gsap.set(elVideo, { yPercent: -28, autoAlpha: 1 })
        gsap.set([elBoxTop, elBoxBot], { autoAlpha: 0, y: 20 })
        tl = gsap.timeline({
          scrollTrigger: {
            trigger: elVideo,
            scrub: true,
            start: 'center 40%',
            end: 'bottom 25%',
            onEnter: () => {
              if (isTopBoxVisible.value) {
                return
              }
              gsap.to(elBoxTop, {
                duration: 0.5,
                y: 20,
                autoAlpha: 1,
                ease: 'ease.inOut',
                onComplete: () => {
                  isTopBoxVisible.value = true
                }
              })
            },
            onUpdate: (self) => {
              if (self.progress >= 0.5 && !isAccordionVisible.value) {
                gsap.to(elBoxBot, {
                  duration: 0.5,
                  y: 20,
                  autoAlpha: 1,
                  ease: 'ease.inOut',
                  onComplete: () => {
                    isAccordionVisible.value = true
                  }
                })
              }
            }
          }
        })
        tl.to(elVideo, {
          yPercent: 0,
          ease: 'ease.inOut'
        })
      }, ctxRef.value as HTMLElement)
    })
  }
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
})
</script>
