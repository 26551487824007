<template>
  <div>
    <div class="full absolute left-0 -top-px h-auto w-full lg:hidden">
      <svg
        class="h-auto w-full"
        width="1440"
        height="150"
        viewBox="0 0 1440 150"
        fill="none"
      ></svg>
    </div>
    <div class="hidden w-full lg:flex">
      <div class="l h-[150px] max-w-[190px] flex-1"></div>
      <div class="c h-[150px] flex-1"></div>
      <div class="r h-[150px] max-w-[190px] flex-1"></div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@screen lg {
  .l {
    background: url('~/assets/images/borders/all-left2.svg') 0 0 no-repeat;
  }
  .c {
    background: url('~/assets/images/borders/all-center2.svg') 0 0 repeat-x;
  }
  .r {
    background: url('~/assets/images/borders/all-right2.svg') 0 0 no-repeat;
  }
}

@media (max-width: 1023px) {
  .full {
    background: url('~/assets/images/borders/all-full.svg') 0 0 / cover no-repeat;
  }
}
</style>
