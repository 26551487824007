<template>
  <div ref="ctxRef">
    <div class="grid-default relative mt-[340px] lg:mt-[420px]">
      <div
        v-for="(item, idx) in packages"
        :key="idx"
        class="fx-package col-span-2 text-center lg:col-span-4"
      >
        <Heading :size="2" class="fx-title text-cyan">{{ item.title }}</Heading>
        <div class="fx-description">
          <div class="text-body font-bold lg:text-bigText">{{ item.subtitle }}</div>
          <div class="text-xs lg:text-bigText" v-html="item.body"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'

export interface PlanetsProps {
  packages: Array<{
    title: string
    subtitle: string
    body: string
  }>
}

defineProps<PlanetsProps>()

const isMounted = ref(false)
const ctxRef = ref<HTMLElement | null>(null)
let ctx: any = null
let tl: GSAPTimeline | null = null

onMounted(() => {
  nextTick(() => {
    isMounted.value = true

    if (ctxRef.value) {
      ctx = gsap.context((self: any) => {
        gsap.set([self.selector('.fx-image')], { opacity: 0 })

        self.selector('.fx-package').forEach((el: HTMLElement) => {
          const elTitle = el.querySelector('.fx-title')
          const elDescription = el.querySelector('.fx-description')
          gsap.set(elTitle, { autoAlpha: 0, y: 10 })
          gsap.set(elDescription, { autoAlpha: 0, y: 10 })

          tl = gsap.timeline({
            scrollTrigger: {
              trigger: ctxRef.value,
              start: 'center 60%',
              markers: false,
              toggleActions: 'play none none none'
            }
          })
          tl.addLabel('start')
          tl.to(
            elTitle,
            {
              autoAlpha: 1,
              y: 0,
              ease: 'power1.out'
            },
            'start'
          )
          tl.to(
            elDescription,
            {
              autoAlpha: 1,
              y: 0,
              ease: 'power1.out'
            },
            'start'
          )
        })
      }, ctxRef.value)
    }
  })
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
})
</script>
