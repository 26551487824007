<template>
  <Box class="w-[300px] transform-gpu px-7 pb-1.5 sm:w-[314px]">
    <div role="presentation">
      <AccordionFeaturesPanel
        v-for="(item, idx) in data"
        :key="idx"
        :idx="idx"
        :active="activeIdx === idx"
        stateful
        @expand="expandAccordion(idx)"
      >
        <template #title>
          <span class="relative">
            <svg
              class="absolute -top-1.5 left-[-50px] h-auto w-10 -translate-x-full transform-gpu transition-transform duration-1000 ease-back-out lg:left-[-63px] lg:top-0 lg:w-auto"
              :class="activeIdx === idx && 'rotate-180'"
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M42.7754 22.8301C42.7754 33.9518 33.7594 42.9678 22.6377 42.9678C11.516 42.9678 2.5 33.9518 2.5 22.8301C2.5 11.7083 11.516 2.69238 22.6377 2.69238C33.7594 2.69238 42.7754 11.7083 42.7754 22.8301Z"
                stroke="#12E2FF"
                stroke-width="4"
              />
              <circle cx="22.6392" cy="22.8302" r="4.59236" stroke="#12E2FF" stroke-width="4" />
              <path d="M22.6367 8.45508V17.9774" stroke="#12E2FF" stroke-width="4" />
              <path d="M8.26172 22.8301L17.784 22.8301" stroke="#12E2FF" stroke-width="4" />
              <path d="M27.4922 22.8301L37.0145 22.8301" stroke="#12E2FF" stroke-width="4" />
            </svg>
            <svg
              class="absolute -left-8 top-0 h-auto w-12 -translate-x-full transform-gpu transition-transform lg:w-auto"
              width="76"
              height="45"
              viewBox="0 0 76 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M41 22.8301H75.5" stroke="#12E2FF" stroke-width="4" />
            </svg>

            <span>{{ item.title }}</span>
          </span></template
        >
        <template #content><div class="pb-2" v-html="item.body"></div></template>
      </AccordionFeaturesPanel>
    </div>
  </Box>
</template>

<script setup lang="ts">
import { AccordionPanelProps } from '~/components/accordion/AccordionPanel.vue'

defineProps({
  data: {
    type: Array as PropType<AccordionPanelProps[]>,
    default: () => []
  }
})

const activeIdx = ref<number>(0)

function expandAccordion(idx: number) {
  activeIdx.value = idx
}
</script>

<style>
svg * {
  transform-box: fill-box;
}
</style>
